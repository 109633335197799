import React from 'react';
import './App.css';
import '../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, VerticalBarSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis } from 'react-vis';
import NavBar from "./components/NavBar";
// New - import the React Router components, and the Profile page component
import { Router, Route, Switch } from "react-router-dom";
import Profile from "./components/Profile";
import history from "./utils/history";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth0Context } from "./react-auth0-spa";


class App extends React.Component {
  static contextType = Auth0Context

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      readings: []
    };
  }

  componentDidMount() {
    try{
        this.context.getTokenSilently();
    }catch (err){

    }
    
    this.loadDataWithTimer();
  }

  loadData(){
    fetch("https://nhkiafd7cl.execute-api.ap-southeast-2.amazonaws.com/prod/items")
    .then(res => res.json())
    .then(
      this.handleData.bind(this),
      this.handleError.bind(this)
    )
  }

  handleData(result){
    this.setState({
      isLoaded: true,
      readings: result.sort((a,b) => a.time - b.time)
     
    });
  }

  handleError(error){
    this.setState({
      isLoaded: true,
      error
    });
  }

  loadDataWithTimer(){
     this.loadData()
     setInterval(this.loadData.bind(this), 10000)
  }

  getCurrentTemp(){
    return this.state.readings[this.state.readings.length-1].temperature.toFixed(1); 
  }

  getCurrentOutdoorTemp(){
    return this.state.readings[this.state.readings.length-1].outdoor_temp.toFixed(1); 
  }

  getCurrentTime(){
    return this.state.readings[this.state.readings.length-1].time; 
  }

  getCurrentStatus(){
    return this.state.readings[this.state.readings.length-1].status; 
  }

  getHeaterData(){
    const lowest = this.getLowestReading();
    return this.state.readings.map(reading => {
      const data = {};
      if (reading.status === "on"){
        data.y = reading.temperature
      }else {
        data.y=lowest -1
      }
      data.y0 = lowest-1;
      data.x = (reading.time - this.getCurrentTime())/(60*60) 
      return data;
    })
  }

  getMax(current, next){
    if ( next.temperature > current || next.outdoor_temp && next.outdoor_temp > current ){
      if(!next.outdoor_temp || next.temperature > next.outdoor_temp){
        return next.temperature;
      }else{
        return next.outdoor_temp;
      }
    }else {
      return current;
    }
  }

  getMin(current, next){
    if ( next.temperature < current || next.outdoor_temp && next.outdoor_temp < current ){
      if(!next.outdoor_temp || next.temperature < next.outdoor_temp){
        return next.temperature;
      }else{
        return next.outdoor_temp;
      }
    }else {
      return current;
    }
  }

  getTempData(){
    return this.state.readings.map(reading => {
      const data = {}
      data.y = reading.temperature;
      data.x = (reading.time - this.getCurrentTime())/(60*60) ;
      return data;
    })
  }

  getOutdoorTempData(){
    return this.state.readings.map(reading => {
      const data = {}
      if(reading.outdoor_temp){
        data.y = reading.outdoor_temp;
      }else{
        data.y = 19;
      }
      data.x = (reading.time - this.getCurrentTime())/(60*60) ;
      return data;
    })
  }

  getHighestReading(){
    return this.state.readings.reduce(this.getMax, 0);
  }

  getLowestReading(){
    return this.state.readings.reduce(this.getMin, 100);
  }

  sumMins(sum, next){
    if ( next.status === "on" ){
      sum = sum + 1;
    }
    return sum;
  }

  getPercentageOnToday(){
    return (this.state.readings.reduce(this.sumMins, 0) / (60 * 48) * 100).toFixed(1);
  }

  render() {
    const { error, isLoaded, readings } = this.state;
      return (
        
        <div className="App">
              
          <Router history={history}>
          <header>
           <NavBar />
            
            <h1>Kallista Monitor</h1>
            
         </header>
         <Switch>
           <Route path="/" exact />
           <Route path="/profile" component={Profile} />
         </Switch>
         </Router>
        
          {error && this.context.isAuthenticated &&
           <div><p>Error: {error.message}</p></div>
          }
          {(!isLoaded  || this.context.loading) &&
            <p><CircularProgress /></p>
          }
          {!this.context.loading && !error && !this.context.isAuthenticated &&
            <p></p>
          }
         

         {isLoaded && !this.context.loading && !error && 
           <div className="content">
          <p>
            Indoor temp: <strong>{this.getCurrentTemp()}&#176;C </strong><br />
            Outdoor temp: <strong>{this.getCurrentOutdoorTemp()}&#176;C </strong><br />
            Heater status: <strong>{this.getCurrentStatus()}</strong><br /> 
            Percentage on today <strong>{this.getPercentageOnToday()}%</strong></p>
          
          
       
          <XYPlot className="temps" height={400} width= {700} yDomain={[this.getLowestReading() - 1,this.getHighestReading() + 1]}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <LineSeries 
            curve="basisOpen" data={this.getOutdoorTempData()} />
            <VerticalBarSeries data={this.getHeaterData()} />
            <LineSeries 
            curve="basisOpen" data={this.getTempData()} />
          </XYPlot>

          
          </div>

          } 
      </div>
      );
    
  }
}

export default App;
