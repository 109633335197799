// src/components/NavBar.js

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, loading, logout, user } = useAuth0();

  return (
    <div>

      <div className="auth">
      {!isAuthenticated && !loading && false && (
        <Button syle="float: right" variant="outlined" onClick={() => loginWithRedirect({})}>Log in</Button>
      )}

      {isAuthenticated && !loading && 
      <div>
      <p>Hi {user.given_name}!</p>
      <p><img src={user.picture} width="100px" /></p>
      <Button variant="outlined" onClick={() => logout()}>Log out</Button>
      </div>
      }
      </div>



     <img src="https://blogmattfraser.files.wordpress.com/2019/04/wp_banner4.png" height="100" />

    </div>
  );
};

export default NavBar;